import { Component, EventEmitter, Input, Output } from '@angular/core';
import { icons } from '../../../../models/fontawesome';
import { ImageClient } from '../../../../_clients/image.client';
import { ToasterService } from '../../../../_services/toaster.service';
import { take } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent {
  Icons = icons;
  @Input() label?: string;
  @Input() className = '';
  @Input() set imageUrl(value: string | undefined) {
    if (value && value.length) {
      this.fileUrl = value;
      this.isFileUploadInactive = false;
    }
  }

  @Output() uploadedImgUrl: EventEmitter<string> = new EventEmitter<string>();

  isFileUploadInactive = true;
  fileLoading = false;
  fileUrl = '';

  constructor(
    private imageService: ImageClient,
    private toaster: ToasterService,
    private translate: TranslocoService
  ) {}

  handleSelectFile(event: Event): void {
    if (event.target) {
      const files = (event.target as HTMLInputElement).files;
      if (files && files[0]) {
        this.handleUploadImage(files[0]);
      }
    }
  }

  handleDeleteImage(): void {
    this.fileUrl = '';
    this.uploadedImgUrl.emit('');
    this.isFileUploadInactive = true;
  }

  handleUploadImage(file: File): void {
    this.isFileUploadInactive = false;
    this.fileLoading = true;
    this.imageService
      .upload(file)
      .pipe(take(1))
      .subscribe({
        next: result => {
          this.fileLoading = false;
          this.fileUrl = result.url;
          this.uploadedImgUrl.emit(this.fileUrl);
        },
        error: (error: HttpErrorResponse) => {
          this.fileLoading = false;
          this.isFileUploadInactive = true;
          this.toaster.error({
            title: this.translate.translate('image_error_occurred'),
            description: error.error.message,
          });
        },
      });
  }
}
