export enum ROLE {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  CASHIER = 'CASHIER',
  WAITER = 'WAITER',
  CHEF = 'CHEF',
}

export const Roles: Record<ROLE, string> = {
  [ROLE.SUPER_ADMIN]: 'Super Admin',
  [ROLE.ADMIN]: 'Admin',
  [ROLE.CASHIER]: 'Kassir',
  [ROLE.WAITER]: 'Ofitsiant',
  [ROLE.CHEF]: 'Oshpaz',
};

export enum PAYMENT_TYPE {
  CASH = 'CASH',
  CARD = 'CARD',
  CLICK = 'CLICK',
  PAYME = 'PAYME',
  UZUM = 'UZUM',
}

export const PaymentTypes: Record<PAYMENT_TYPE, string> = {
  CASH: 'Naqd',
  CARD: 'Karta',
  CLICK: 'Click',
  PAYME: 'Payme',
  UZUM: 'Uzum',
};

export enum ORDER_TYPE {
  IN_PLACE = 'IN_PLACE',
  TAKEAWAY = 'TAKEAWAY',
  DELIVERY = 'DELIVERY',
}

export const OrderTypes: Record<ORDER_TYPE, string> = {
  IN_PLACE: 'Zal',
  TAKEAWAY: 'Olib ketish',
  DELIVERY: 'Yetkazish',
};

export enum ORDER_STATUS {
  STARTED = 'STARTED',
  // COMPLETED = 'COMPLETED',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
}

export const OrderStatus: Record<ORDER_STATUS, string> = {
  STARTED: 'Aktiv',
  // COMPLETED: 'Yakunlangan',
  CLOSED: "To'langan",
  CANCELLED: 'Bekor qilingan',
};

export enum ITEM_UNIT_TYPE {
  COUNT = 'COUNT',
  WEIGHT_KILO = 'WEIGHT_KILO',
}

export const UnitType: Record<ITEM_UNIT_TYPE, string> = {
  COUNT: 'Dona',
  WEIGHT_KILO: 'Kg',
};

export enum COMPONENT_UNIT_TYPE {
  GRAM = 'GRAM',
  KILOGRAM = 'KILOGRAM',
  LITER = 'LITER',
  MILLILITER = 'MILLILITER',
  PIECE = 'PIECE',
  PACK = 'PACK',
}

export const ComponentUnitType: Record<COMPONENT_UNIT_TYPE, string> = {
  GRAM: 'Gram',
  KILOGRAM: 'Kg',
  LITER: 'Litr',
  MILLILITER: 'ml',
  PIECE: 'Dona',
  PACK: 'Karobka',
};
