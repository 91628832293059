import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() size? = 'md';
  @Input() backdrop? = true;
  @Input() title? = 'Modal title';
  @Input() submitText?: string;
  @Input() cancelText?: string;
  @Input() submitDisabled: Observable<boolean> = new Observable();
  @Input() showSubmit = true;
  @Input() showCancel = false;
  @Input() kind: 'default' | 'danger' = 'default';
  @Input() beforeSubmit = () => Promise.resolve(true);

  @Output() closeEvent = new EventEmitter();
  @Output() submitEvent = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  close(closeable = true): void {
    if (closeable) {
      this.elementRef.nativeElement.remove();
      this.closeEvent.emit();
    }
  }

  async submit() {
    const beforeSubmit = await this.beforeSubmit();
    if (beforeSubmit) {
      this.elementRef.nativeElement.remove();
      this.submitEvent.emit();
    }
  }
}
