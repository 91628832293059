import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricePipe } from './pipes/price.pipe';
import { ImageUrlPipe } from './pipes/image-url.pipe';
import { LocalizePipe } from './pipes/localize.pipe';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { BurgerBtnComponent } from './components/burger-btn/burger-btn.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ListCardComponent } from './components/list-card/list-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink } from '@angular/router';
import { BadgeComponent } from './components/badge/badge.component';
import { CheckWidthPipe } from './pipes/check-width.pipe';
import { InputComponent } from './components/common/input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToasterComponent } from './components/common/toaster/toaster.component';
import { SelectComponent } from './components/common/select/select.component';
import { ModalComponent } from './components/common/modal/modal.component';
import { TextareaComponent } from './components/common/textarea/textarea.component';
import { SearchInputComponent } from './components/common/search-input/search-input.component';
import { ButtonComponent } from './components/common/button/button.component';
import { OptionsDropdownComponent } from './components/options-dropdown/options-dropdown.component';
import { CardSkeletonComponent } from './components/card-skeleton/card-skeleton.component';
import { GenerateArrayPipe } from './pipes/generate-array.pipe';
import { DatepickerComponent } from './components/common/datepicker/datepicker.component';
import { StatusCardComponent } from './components/status-card/status-card.component';
import { TableComponent } from './components/common/table/table.component';
import { ImageUploadComponent } from './components/common/image-upload/image-upload.component';
import { ChipDatepickerComponent } from './components/chip-style/chip-datepicker/chip-datepicker.component';
import { CalendarComponent } from './components/common/calendar/calendar.component';
import { ChipDropdownComponent } from './components/chip-style/chip-dropdown/chip-dropdown.component';
import { ChipMultiselectComponent } from './components/chip-style/chip-multiselect/chip-multiselect.component';
import { ChipInputComponent } from './components/chip-style/chip-input/chip-input.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MultiSelectComponent } from './components/common/multi-select/multi-select.component';
import { ChequeComponent } from './components/cheque/cheque.component';
import { NumberFormatDirective } from './directives/number-format.directive';
import { EmptyPlaceholderComponent } from './components/empty-placeholder/empty-placeholder.component';
import { ToggleComponent } from './components/common/toggle/toggle.component';
import { AdvancedSelectComponent } from './components/common/advanced-select/advanced-select.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [
    PricePipe,
    ImageUrlPipe,
    LocalizePipe,
    DragAndDropDirective,
    BurgerBtnComponent,
    ClickOutsideDirective,
    ListCardComponent,
    BadgeComponent,
    CheckWidthPipe,
    InputComponent,
    ToasterComponent,
    SelectComponent,
    ModalComponent,
    TextareaComponent,
    SearchInputComponent,
    ButtonComponent,
    OptionsDropdownComponent,
    CardSkeletonComponent,
    GenerateArrayPipe,
    DatepickerComponent,
    StatusCardComponent,
    TableComponent,
    ImageUploadComponent,
    ChipDatepickerComponent,
    CalendarComponent,
    ChipDropdownComponent,
    ChipMultiselectComponent,
    ChipInputComponent,
    MultiSelectComponent,
    ChequeComponent,
    NumberFormatDirective,
    EmptyPlaceholderComponent,
    ToggleComponent,
    AdvancedSelectComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatAutocompleteModule,
    MatOptionModule,
    TranslocoDirective,
  ],
  exports: [
    PricePipe,
    ImageUrlPipe,
    LocalizePipe,
    BurgerBtnComponent,
    ClickOutsideDirective,
    ListCardComponent,
    BadgeComponent,
    CheckWidthPipe,
    InputComponent,
    ToasterComponent,
    SelectComponent,
    TextareaComponent,
    SearchInputComponent,
    ImageUploadComponent,
    OptionsDropdownComponent,
    CardSkeletonComponent,
    DatepickerComponent,
    ButtonComponent,
    StatusCardComponent,
    TableComponent,
    ChipDatepickerComponent,
    ChipDropdownComponent,
    ChipMultiselectComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    MultiSelectComponent,
    ChequeComponent,
    EmptyPlaceholderComponent,
    ToggleComponent,
    GenerateArrayPipe,
  ],
  providers: [provideNgxMask()],
})
export class SharedModule {}
