import { AppNavigationItem, TableColumn } from './types';
import {
  COMPONENT_UNIT_TYPE,
  ComponentUnitType,
  ITEM_UNIT_TYPE,
  ORDER_STATUS,
  ORDER_TYPE,
  OrderStatus,
  OrderTypes,
  PAYMENT_TYPE,
  PaymentTypes,
  ROLE,
  Roles,
  UnitType,
} from './enums';

export const customTabletWidth = 992;

export const appSidebarNavigations: AppNavigationItem[] = [
  {
    route: '/home',
    title: 'Analitika',
    icon: 'home',
    slug: 'home',
    hasMobileRoute: false,
  },
  {
    route: '/menu',
    title: 'Menu',
    icon: 'bellConcierge',
    slug: 'menu',
    hasMobileRoute: false,
  },
  {
    route: '/orders',
    title: 'Buyurtma',
    icon: 'cartShopping',
    slug: 'orders',
    hasMobileRoute: false,
  },
  {
    route: '/history',
    title: 'Tarix',
    icon: 'clock',
    slug: 'history',
    hasMobileRoute: false,
  },
  {
    route: '/inventory',
    title: 'Inventory',
    icon: 'boxesStacked',
    slug: 'inventory',
    hasMobileRoute: false,
  },
  {
    route: '/settings',
    title: 'Sozlama',
    icon: 'gear',
    slug: 'settings',
    hasMobileRoute: true,
  },
];

export const categoryTableColumns: TableColumn[] = [
  {
    name: 'Kategoriya',
    classNames: 'px-6 py-3',
  },
  {
    name: 'Soni',
    classNames: 'px-6 py-3',
  },
  {
    name: "Ja'mi",
    classNames: 'px-6 py-3',
  },
];
export const topSaleTableColumns: TableColumn[] = [
  {
    name: 'Taom',
    classNames: 'px-6 py-3',
  },
  {
    name: 'Soni',
    classNames: 'px-6 py-3',
  },
];
export const paymentTypeTableColumns: TableColumn[] = [
  {
    name: "To'lov turi",
    classNames: 'px-6 py-3',
  },
  {
    name: 'Soni',
    classNames: 'px-6 py-3',
  },
  {
    name: "Ja'mi",
    classNames: 'px-6 py-3',
  },
];
export const historyTableColumns: TableColumn[] = [
  {
    name: 'Stol',
    classNames: 'px-6 py-3',
  },
  {
    name: 'Sana',
    classNames: 'px-6 py-3',
  },
  {
    name: 'Qiymat',
    classNames: 'px-6 py-3',
  },
  {
    name: 'Chegirma',
    classNames: 'px-6 py-3',
  },
  {
    name: "To'lov Turi",
    classNames: 'px-6 py-3',
  },
  {
    name: 'Buyurtma holati',
    classNames: 'px-6 py-3',
  },
  {
    name: 'Buyurtma turi',
    classNames: 'px-6 py-3',
  },
];
export const warehouseComponentTableColumns: TableColumn[] = [
  {
    name: 'Nomi',
    classNames: 'pl-3 pr-6 py-3',
  },
  {
    name: 'Qiymat',
    classNames: 'px-6 py-3',
  },
  {
    name: '',
    classNames: 'px-6 py-3',
  },
];
export const inventoryTableColumns: TableColumn[] = [
  {
    name: 'Nomi',
    classNames: 'pl-3 pr-6 py-3',
  },
  {
    name: 'Ishlatilgan',
    classNames: 'px-6 py-3',
  },
  {
    name: 'Qolgan',
    classNames: 'px-6 py-3 text-right',
  },
];
export const kitchenTableColumns: TableColumn[] = [
  {
    name: 'Nomi',
    classNames: 'pl-3 pr-6 py-3',
  },
  {
    name: '',
    classNames: 'px-6 py-3',
  },
];

export const DiscountDropdownItems = [
  {
    label: 'Barchasi',
    value: undefined,
  },
  {
    label: 'Bor',
    value: true,
  },
  {
    label: "Yo'q",
    value: false,
  },
];
export const PaymentTypeDropdownItems = [
  {
    label: 'Barchasi',
    value: undefined,
  },
  {
    label: PaymentTypes.CASH,
    value: PAYMENT_TYPE.CASH,
  },
  {
    label: PaymentTypes.CARD,
    value: PAYMENT_TYPE.CARD,
  },
];
export const StatusDropdownItems = [
  {
    label: 'Barchasi',
    value: undefined,
  },
  {
    label: OrderStatus.CLOSED,
    value: ORDER_STATUS.CLOSED,
  },
  {
    label: OrderStatus.CANCELLED,
    value: ORDER_STATUS.CANCELLED,
  },
];
export const OrderTypeDropdownItems = [
  {
    label: 'Barchasi',
    value: undefined,
  },
  {
    label: OrderTypes.IN_PLACE,
    value: ORDER_TYPE.IN_PLACE,
  },
  {
    label: OrderTypes.TAKEAWAY,
    value: ORDER_TYPE.TAKEAWAY,
  },
  {
    label: OrderTypes.DELIVERY,
    value: ORDER_TYPE.DELIVERY,
  },
];

export const UnitTypeDropdownItems = [
  {
    text: UnitType.COUNT,
    value: ITEM_UNIT_TYPE.COUNT,
  },
  {
    text: UnitType.WEIGHT_KILO,
    value: ITEM_UNIT_TYPE.WEIGHT_KILO,
  },
];

export const ComponentUnitTypeDropdownItems = [
  { text: ComponentUnitType.GRAM, value: COMPONENT_UNIT_TYPE.GRAM },
  { text: ComponentUnitType.KILOGRAM, value: COMPONENT_UNIT_TYPE.KILOGRAM },
  { text: ComponentUnitType.LITER, value: COMPONENT_UNIT_TYPE.LITER },
  { text: ComponentUnitType.PIECE, value: COMPONENT_UNIT_TYPE.PIECE },
  { text: ComponentUnitType.PACK, value: COMPONENT_UNIT_TYPE.PACK },
  {
    text: ComponentUnitType.MILLILITER,
    value: COMPONENT_UNIT_TYPE.MILLILITER,
  },
];

export const UserRoleDropdownItems = [
  {
    text: Roles[ROLE.CASHIER],
    value: ROLE.CASHIER,
  },
  {
    text: Roles[ROLE.WAITER],
    value: ROLE.WAITER,
  },
];
