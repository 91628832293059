import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DefaultValueAccessor, FormGroup } from '@angular/forms';
import { IconNames, icons } from '../../../../models/fontawesome';
import { AnimationProp } from '@fortawesome/angular-fontawesome';
import { DropdownItem } from '../../../../models/types';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent extends DefaultValueAccessor implements OnInit {
  @Input() form!: FormGroup;
  @Input() control!: string;
  @Input() formStyleType: 'solid' | 'simple' = 'simple';
  @Input() label: string | undefined;
  @Input() placeholder: string | undefined;
  @Input() inputType: 'text' | 'password' | 'number' | 'autocomplete' = 'text';
  @Input() errorText: string | undefined;
  @Input() className = '';
  @Input() inputMode:
    | 'decimal'
    | 'email'
    | 'none'
    | 'numeric'
    | 'search'
    | 'tel'
    | 'text'
    | 'url' = 'text';
  @Input() suffixIconAnimation: AnimationProp | undefined;
  @Input() suffixIcon: IconNames | undefined;
  @Input() prefixIconAnimation: AnimationProp | undefined;
  @Input() prefixIcon: IconNames | undefined;
  @Input() componentsDropdownItems: DropdownItem[] | null = [];

  @Output() enter: EventEmitter<void> = new EventEmitter<void>();
  @Output() optionSelected = new EventEmitter<DropdownItem>();
  readonly Icons = icons;

  filteredAutocompleteItems: DropdownItem[] = [];

  ngOnInit(): void {
    this.filteredAutocompleteItems = Array.from(
      this.componentsDropdownItems || []
    );
  }

  handleEnterAutocompleteEvent(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const data = this.componentsDropdownItems?.find(
      component => component.value === Number(target.value)
    );
    if (data) {
      this.handleOptionSelected(data);
    }
  }

  handleAutoCompleteInputChange(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this.filteredAutocompleteItems =
      this.componentsDropdownItems?.filter(item =>
        item.text.toLowerCase().startsWith(inputValue.toLowerCase())
      ) || [];
  }

  handleOptionSelected(event: DropdownItem): void {
    console.log(event);
    this.form.get(this.control)?.patchValue(event);
    this.optionSelected.emit(event);
  }
}
