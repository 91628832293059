import { Injectable } from '@angular/core';
import { Menu } from '../models/menu.model';
import { Category } from '../models/category.model';
import { Item } from '../models/item.model';
import { environment } from '../../environments/environment';
import { map, Observable, switchMap } from 'rxjs';
import { Cart } from '../models/cart.model';
import { Order } from '../models/order.model';
import { ORDER_TYPE } from '../models/enums';
import { MenuClient } from '../_clients/menu.client';
import { Store } from '@ngrx/store';
import { selectCategories } from '../store/selectors/category/category.selector';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private readonly baseUrl = environment.baseApiUrl;
  private _menu: Menu[] = [];
  private _categories: Category[] = [];
  private _items: Item[] = [];
  cart: Cart = new Cart({
    items: new Map(),
    note: null,
    tableId: null,
    orderType: ORDER_TYPE.IN_PLACE,
    customerPhone: null,
    specialInstructions: null,
  });
  selectedOrder: Order | undefined;

  get items(): Item[] {
    return this._items;
  }

  set items(value: Item[]) {
    this._items = value;
  }

  clear(): void {
    this._menu = [];
    this._categories = [];
    this._items = [];
  }

  constructor(
    private menuClient: MenuClient,
    private store$: Store
  ) {}

  getData(): Observable<{
    menu: Menu[];
    categories: Category[];
    items: Item[];
  }> {
    return this.menuClient.get().pipe(
      map(menus => {
        this._menu = menus;
        this._items = menus.flatMap(menu => menu.items); // Simplifies item extraction
        return {
          menu: this._menu,
          items: this._items,
        };
      }),
      switchMap(data =>
        this.store$.select(selectCategories).pipe(
          map(categories => ({
            ...data,
            categories,
          }))
        )
      )
    );
  }
}
