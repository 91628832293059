<div class="layout-wrapper font-poppins">
  <div
    class="layout-drawer"
    id="drawer"
    [ngClass]="{ 'layout-drawer-active': drawerVisible }">
    <div class="logo">
      <span class="font-semibold tracking-wide">ORDIN</span>
    </div>
    <div class="navigation-list" *transloco="let t">
      <ul class="dynamic-list">
        <ng-container
          *ngFor="
            let navigation of getNavigationList((authorizedUser | async)!)
          ">
          <li
            class="navigation-item"
            [routerLink]="
              navigation.hasMobileRoute
                ? navigation.route + '/mobile'
                : navigation.route
            "
            routerLinkActive="active-navigation-item">
            <fa-icon
              class="mr-3 md:mr-0"
              [icon]="Icons[navigation.icon]"></fa-icon>
            <span class="tracking-wide">
              {{ t(navigation.title) }}
            </span>
          </li>
        </ng-container>
      </ul>
      <div
        routerLink="profile/mobile"
        routerLinkActive="active-navigation-item"
        class="h-max navigation-item bg-white">
        <fa-icon class="mr-3 md:mr-0" [icon]="Icons.circleUser"></fa-icon>
        <span class="tracking-wide">{{ t('app_menu.profile') }}</span>
      </div>
    </div>
  </div>
  <div class="layout-content">
    <div class="layout-header bg-white text-custom-gray-600">
      <ng-container
        *ngIf="leftSideHeaderTemplate$ | async; else defaultLeftSideHeader"
        [ngTemplateOutlet]="leftSideHeaderTemplate$ | async">
      </ng-container>
      <ng-container
        *ngIf="
          middleSideHeaderTemplate$ | async;
          else defaultMiddleHeaderTemplate
        "
        [ngTemplateOutlet]="middleSideHeaderTemplate$ | async">
      </ng-container>
      <ng-container
        *ngIf="rightSideHeaderTemplate$ | async; else defaultRightSideHeader"
        [ngTemplateOutlet]="rightSideHeaderTemplate$ | async"></ng-container>
    </div>
    <div class="layout-body sm:p-0">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<ng-template #defaultLeftSideHeader>
  <app-burger-btn
    class="p-4 flex cursor-pointer justify-center items-center"
    (click)="drawerVisible = true">
  </app-burger-btn>
</ng-template>

<ng-template #defaultMiddleHeaderTemplate>
  <div
    class="font-medium text-base tracking-widest text-black"
    *transloco="let t">
    {{ t(title) }}
  </div>
</ng-template>

<ng-template #defaultRightSideHeader>
  <div class="w-2/12"></div>
</ng-template>
