import { isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppInterceptor } from './core/services/app.interceptor';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from './shared/shared.module';
import { AppLayoutComponent } from './core/layouts/app-layout/app-layout.component';
import { PersonalInfoComponent } from './pages/profile/personal-info/personal-info.component';
import { ChangePasswordComponent } from './pages/profile/change-password/change-password.component';
import { ProfileMainPageComponent } from './pages/profile/profile-main-page/profile-main-page.component';
import localUz from '@angular/common/locales/uz';
import { AsyncPipe, registerLocaleData } from '@angular/common';
import { DesktopLayoutComponent } from './core/layouts/desktop-layout/desktop-layout.component';
import { MobileLayoutComponent } from './core/layouts/mobile-layout/mobile-layout.component';
import { DesktopViewComponent } from './pages/profile/_components/desktop-view/desktop-view.component';
import { MobileViewComponent } from './pages/profile/_components/mobile-view/mobile-view.component';
import { LogoutConfirmTemplateComponent } from './pages/profile/_components/logout-confirm-template/logout-confirm-template.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appFeatureKey, reducers } from './store/reducers/index.reducer';
import { CoreEffect } from './store/effects/core/core.effect';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CategoryEffect } from './store/effects/category/category.effect';
import { OrdersEffect } from './store/effects/orders/orders.effect';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { TablesEffect } from './store/effects/tables/tables.effect';
import { UsersEffect } from './store/effects/users/users.effect';
import { HistoryEffect } from './store/effects/history/history.effect';
import { ComponentsEffect } from './store/effects/warehouse/components.effect';
import { TranslocoRootModule } from './transloco-root.module';

registerLocaleData(localUz);

@NgModule({
  declarations: [
    AppComponent,
    NotFoundPageComponent,
    AppLayoutComponent,
    PersonalInfoComponent,
    ChangePasswordComponent,
    ProfileMainPageComponent,
    DesktopLayoutComponent,
    MobileLayoutComponent,
    DesktopViewComponent,
    MobileViewComponent,
    LogoutConfirmTemplateComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    SharedModule,
    ReactiveFormsModule,
    StoreModule.forRoot(),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
    StoreModule.forFeature(appFeatureKey, reducers),
    EffectsModule.forFeature([
      CoreEffect,
      CategoryEffect,
      OrdersEffect,
      TablesEffect,
      UsersEffect,
      HistoryEffect,
      ComponentsEffect,
    ]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    TranslocoRootModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'uz' },
    provideEnvironmentNgxMask(),
    AsyncPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
