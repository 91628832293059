import { createSelector } from '@ngrx/store';
import { selectAppState } from '../../reducers/index.reducer';
import * as CategoryReducer from '../../reducers/category/category.reducer';

export const selectCategoryState = createSelector(
  selectAppState,
  (state): CategoryReducer.State => state[CategoryReducer.featureKey]
);

export const selectCategories = createSelector(
  selectCategoryState,
  state => state.records
);

export const selectCategoriesAsDropdownItem = createSelector(
  selectCategoryState,
  state =>
    state.records.map(category => ({
      text: category.name,
      value: category.id,
    }))
);
