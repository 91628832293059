<ng-container *ngIf="label; else noLabel">
  <form [formGroup]="form" class="{{ className }}">
    <label class="block">
      <span class="text-gray-700">{{ label }}</span>
      <select
        formControlName="{{ control }}"
        [ngClass]="{
          'border-primary': formStyleType === 'simple',
          'focus:ring-red-400 ring-red-400 focus:border-red-400 border-red-400':
            errorText,
          'bg-gray-100': formStyleType === 'solid',
          'border-transparent': formStyleType === 'solid' && !errorText
        }"
        class="mt-1 block w-full rounded-md focus:border-primary-light focus:bg-white">
        <option [ngValue]="null" class="text-gray-400">
          {{ placeholder }}
        </option>
        <ng-container *ngFor="let item of items">
          <option [ngValue]="item.value" *transloco="let t">
            {{ t(item.text) }}
          </option>
        </ng-container>
      </select>
      <small *ngIf="errorText" class="text-red-400 text-xs">
        {{ errorText }}
      </small>
    </label>
  </form>
</ng-container>

<ng-template #noLabel>
  <form [formGroup]="form" class="{{ className }}">
    <select
      formControlName="{{ control }}"
      [ngClass]="{
        'border-primary': formStyleType === 'simple',
        'focus:ring-red-400 ring-red-400 focus:border-red-400 border-red-400':
          errorText,
        'bg-gray-100': formStyleType === 'solid',
        'border-transparent': formStyleType === 'solid' && !errorText
      }"
      class="mt-1 block w-full rounded-md focus:border-primary-light focus:bg-white">
      <option [ngValue]="null" disabled>
        {{ placeholder }}
      </option>
      <ng-container *ngFor="let item of items">
        <option [ngValue]="item.value" *transloco="let t">
          {{ t(item.text) }}
        </option>
      </ng-container>
    </select>
    <small *ngIf="errorText" class="text-red-400 text-xs">
      {{ errorText }}
    </small>
  </form>
</ng-template>
