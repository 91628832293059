import { AppNavigationItem, TableColumn } from './types';
import {
  COMPONENT_UNIT_TYPE,
  ITEM_UNIT_TYPE,
  ORDER_STATUS,
  ORDER_TYPE,
  PAYMENT_TYPE,
  ROLE,
} from './enums';

export const customTabletWidth = 992;

export const appSidebarNavigations: AppNavigationItem[] = [
  {
    route: '/home',
    title: 'app_menu.analytics',
    icon: 'home',
    slug: 'home',
    hasMobileRoute: false,
  },
  {
    route: '/menu',
    title: 'app_menu.menu',
    icon: 'bellConcierge',
    slug: 'menu',
    hasMobileRoute: false,
  },
  {
    route: '/orders',
    title: 'app_menu.orders',
    icon: 'cartShopping',
    slug: 'orders',
    hasMobileRoute: false,
  },
  {
    route: '/history',
    title: 'app_menu.orders_history',
    icon: 'clock',
    slug: 'history',
    hasMobileRoute: false,
  },
  {
    route: '/inventory',
    title: 'app_menu.inventory',
    icon: 'boxesStacked',
    slug: 'inventory',
    hasMobileRoute: false,
  },
  {
    route: '/settings',
    title: 'app_menu.settings',
    icon: 'gear',
    slug: 'settings',
    hasMobileRoute: true,
  },
];

export const categoryTableColumns: TableColumn[] = [
  {
    name: 'category',
    classNames: 'px-6 py-3',
  },
  {
    name: 'quantity',
    classNames: 'px-6 py-3',
  },
  {
    name: 'total',
    classNames: 'px-6 py-3',
  },
];
export const topSaleTableColumns: TableColumn[] = [
  {
    name: 'meal',
    classNames: 'px-6 py-3',
  },
  {
    name: 'quantity',
    classNames: 'px-6 py-3',
  },
];
export const paymentTypeTableColumns: TableColumn[] = [
  {
    name: 'payment_type',
    classNames: 'px-6 py-3',
  },
  {
    name: 'quantity',
    classNames: 'px-6 py-3',
  },
  {
    name: 'total',
    classNames: 'px-6 py-3',
  },
];
export const historyTableColumns: TableColumn[] = [
  {
    name: 'table',
    classNames: 'px-6 py-3',
  },
  {
    name: 'date',
    classNames: 'px-6 py-3',
  },
  {
    name: 'amount',
    classNames: 'px-6 py-3',
  },
  {
    name: 'discount',
    classNames: 'px-6 py-3',
  },
  {
    name: 'payment_type',
    classNames: 'px-6 py-3',
  },
  {
    name: 'order_status',
    classNames: 'px-6 py-3',
  },
  {
    name: 'order_type',
    classNames: 'px-6 py-3',
  },
];
export const warehouseComponentTableColumns: TableColumn[] = [
  {
    name: 'name',
    classNames: 'pl-3 pr-6 py-3',
  },
  {
    name: 'amount',
    classNames: 'px-6 py-3',
  },
  {
    name: '',
    classNames: 'px-6 py-3',
  },
];
export const inventoryTableColumns: TableColumn[] = [
  {
    name: 'name',
    classNames: 'pl-3 pr-6 py-3',
  },
  {
    name: 'consumed',
    classNames: 'px-6 py-3',
  },
  {
    name: 'the_rest',
    classNames: 'px-6 py-3 text-right',
  },
];
export const kitchenTableColumns: TableColumn[] = [
  {
    name: 'name',
    classNames: 'pl-3 pr-6 py-3',
  },
  {
    name: '',
    classNames: 'px-6 py-3',
  },
];

export const DiscountDropdownItems = [
  {
    label: 'all',
    value: undefined,
  },
  {
    label: 'has',
    value: true,
  },
  {
    label: 'has_not',
    value: false,
  },
];
export const PaymentTypeDropdownItems = [
  {
    label: 'all',
    value: undefined,
  },
  {
    label: 'payment_type_enum.' + PAYMENT_TYPE.CASH,
    value: PAYMENT_TYPE.CASH,
  },
  {
    label: 'payment_type_enum.' + PAYMENT_TYPE.CARD,
    value: PAYMENT_TYPE.CARD,
  },
  {
    label: 'payment_type_enum.' + PAYMENT_TYPE.CLICK,
    value: PAYMENT_TYPE.CLICK,
  },
  {
    label: 'payment_type_enum.' + PAYMENT_TYPE.PAYME,
    value: PAYMENT_TYPE.PAYME,
  },
];
export const StatusDropdownItems = [
  {
    label: 'all',
    value: undefined,
  },
  {
    label: 'order_status_enum.' + ORDER_STATUS.CLOSED,
    value: ORDER_STATUS.CLOSED,
  },
  {
    label: 'order_status_enum.' + ORDER_STATUS.CANCELLED,
    value: ORDER_STATUS.CANCELLED,
  },
];
export const OrderTypeDropdownItems = [
  {
    label: 'all',
    value: undefined,
  },
  {
    label: 'order_type_enum.' + ORDER_TYPE.IN_PLACE,
    value: ORDER_TYPE.IN_PLACE,
  },
  {
    label: 'order_type_enum.' + ORDER_TYPE.TAKEAWAY,
    value: ORDER_TYPE.TAKEAWAY,
  },
  {
    label: 'order_type_enum.' + ORDER_TYPE.DELIVERY,
    value: ORDER_TYPE.DELIVERY,
  },
];

export const UnitTypeDropdownItems = [
  {
    text: 'unit_type_enum.' + ITEM_UNIT_TYPE.COUNT,
    value: ITEM_UNIT_TYPE.COUNT,
  },
  {
    text: 'unit_type_enum.' + ITEM_UNIT_TYPE.WEIGHT_KILO,
    value: ITEM_UNIT_TYPE.WEIGHT_KILO,
  },
];

export const ComponentUnitTypeDropdownItems = [
  {
    text: 'unit_type_enum.' + COMPONENT_UNIT_TYPE.GRAM,
    value: COMPONENT_UNIT_TYPE.GRAM,
  },
  {
    text: 'unit_type_enum.' + COMPONENT_UNIT_TYPE.KILOGRAM,
    value: COMPONENT_UNIT_TYPE.KILOGRAM,
  },
  {
    text: 'unit_type_enum.' + COMPONENT_UNIT_TYPE.LITER,
    value: COMPONENT_UNIT_TYPE.LITER,
  },
  {
    text: 'unit_type_enum.' + COMPONENT_UNIT_TYPE.PIECE,
    value: COMPONENT_UNIT_TYPE.PIECE,
  },
  {
    text: 'unit_type_enum.' + COMPONENT_UNIT_TYPE.PACK,
    value: COMPONENT_UNIT_TYPE.PACK,
  },
  {
    text: 'unit_type_enum.' + COMPONENT_UNIT_TYPE.MILLILITER,
    value: COMPONENT_UNIT_TYPE.MILLILITER,
  },
];

export const UserRoleDropdownItems = [
  {
    text: 'role_enum.' + ROLE.CASHIER,
    value: ROLE.CASHIER,
  },
  {
    text: 'role_enum.' + ROLE.WAITER,
    value: ROLE.WAITER,
  },
];
