import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EnvironmentInjector,
  Inject,
  Injectable,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ModalComponent } from '../shared/components/common/modal/modal.component';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { ModalOptions } from '../models/types';
import { ConfirmationModalComponent } from '../shared/components/common/modal/confirmation-modal/confirmation-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  set appContainerRef(value: ViewContainerRef) {
    this._appContainerRef = value;
  }

  newModalComponent!: ComponentRef<ModalComponent>;
  private _appContainerRef!: ViewContainerRef;
  private modalNotifier?: Subject<'confirm' | 'cancel'>;

  constructor(
    private appRef: ApplicationRef,
    private injector: EnvironmentInjector,
    @Inject(DOCUMENT) private document: Document
  ) {}

  openWithTemplate(content: TemplateRef<Element>, options?: ModalOptions) {
    this._appContainerRef.clear();

    const innerContent = this._appContainerRef.createEmbeddedView(content);

    this.newModalComponent = this._appContainerRef.createComponent(
      ModalComponent,
      {
        environmentInjector: this.injector,
        projectableNodes: [innerContent.rootNodes],
      }
    );

    this._assignInstanceOptions(options);

    this.newModalComponent.hostView.detectChanges();

    this.modalNotifier = new Subject();
    return this.modalNotifier?.asObservable();
  }

  openWithComponent(component: any, options?: ModalOptions) {
    const newComponent = createComponent(component, {
      environmentInjector: this.injector,
    });

    if (options?.kind === 'danger') {
      (
        newComponent.instance as ConfirmationModalComponent
      ).confirmationItemName = options?.confirmationItemName ?? '';
      newComponent.hostView.detectChanges();
    }

    this.newModalComponent = createComponent(ModalComponent, {
      environmentInjector: this.injector,
      projectableNodes: [[newComponent.location.nativeElement]],
    });

    this._assignInstanceOptions(options);

    this.newModalComponent.hostView.detectChanges();

    this.document.body.appendChild(
      this.newModalComponent.location.nativeElement
    );

    this.modalNotifier = new Subject();
    return this.modalNotifier?.asObservable();
  }

  closeModal() {
    this.modalNotifier?.next('cancel');
    this.modalNotifier?.complete();
  }

  submitModal() {
    this.modalNotifier?.next('confirm');
    this.modalNotifier?.complete();
  }

  private _assignInstanceOptions(options?: ModalOptions): void {
    this.newModalComponent.instance.size = options?.size;
    this.newModalComponent.instance.title = options?.title;
    this.newModalComponent.instance.backdrop = options?.backdrop;
    this.newModalComponent.instance.cancelText = options?.cancelText;
    this.newModalComponent.instance.submitText = options?.submitText;
    this.newModalComponent.instance.showSubmit = options?.showSubmit ?? true;
    this.newModalComponent.instance.showCancel = options?.showCancel ?? false;
    this.newModalComponent.instance.kind = options?.kind ?? 'default';

    if (options?.submitDisabled) {
      this.newModalComponent.instance.submitDisabled = options.submitDisabled;
    }

    this.newModalComponent.instance.beforeSubmit =
      options?.beforeSubmit ?? (() => Promise.resolve(true));

    this.newModalComponent.instance.closeEvent.subscribe(() =>
      this.closeModal()
    );
    this.newModalComponent.instance.submitEvent.subscribe(async () => {
      const beforeSubmit = await this.newModalComponent.instance.beforeSubmit();
      if (beforeSubmit) {
        this.submitModal();
      }
    });
  }
}
