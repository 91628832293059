<div
  class="modal rounded-2xl border max-md:w-11/12 border-blue-100 bg-white p-4 shadow-lg sm:p-6 lg:p-8
{{ size }}">
  <div
    class="modal-header"
    [ngClass]="{
      'justify-between': title?.length,
      'justify-end': !title?.length
    }">
    {{ title }}
    <span
      class="modal-close px-2 hover:text-white hover:bg-gray-700/50 hover:rounded-full"
      (click)="close()">
      ✕
    </span>
  </div>
  <div class="modal-content">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="showCancel"
      (click)="close()"
      type="button"
      class="inline-block w-full rounded-lg bg-custom-gray-300 px-5 py-3 text-center text-sm font-semibold text-custom-gray-500 sm:w-auto">
      {{ cancelText ?? 'Bekor qilish' }}
    </button>
    <button
      *ngIf="showSubmit"
      [ngClass]="{
        'bg-primary': kind === 'default',
        'bg-red-400': kind === 'danger',
        'bg-primary-light cursor-not-allowed': submitDisabled | async
      }"
      [disabled]="submitDisabled | async"
      class="inline-block w-full rounded-lg px-5 py-3 text-center text-sm font-semibold text-white sm:w-auto"
      (click)="submit()">
      {{ submitText ?? 'Tasdiqlash' }}
    </button>
  </div>
</div>

<div class="modal-backdrop" (click)="close(backdrop)"></div>
