import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {
  constructor(private translate: TranslocoService) {}

  transform(value: number | string, local = 'ru'): string {
    let result = ' ' + this.translate.translate<string>('uzb_currency');
    if (typeof value === 'number') {
      result = value.toLocaleString(local) + result;
    } else {
      result = Number(value).toLocaleString(local) + result;
    }
    return result;
  }
}
