import { Component, EventEmitter, Input, Output } from '@angular/core';
import { icons } from '../../../../models/fontawesome';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-chip-multiselect',
  templateUrl: './chip-multiselect.component.html',
  styleUrls: ['./chip-multiselect.component.scss'],
})
export class ChipMultiselectComponent {
  readonly Icons = icons;
  selectedValuesMap = new Map();
  showDropdown = false;

  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() items: { label: string; value: any }[] = [];
  @Input() set defaultValues(data: { label: string; value: any }[]) {
    this.selectedValuesMap.clear();
    data.forEach(item => {
      this.selectedValuesMap.set(item.value, item.label);
    });
  }

  constructor(private translate: TranslocoService) {}

  @Output() selectedValuesChanged = new EventEmitter<any[]>();

  selectedValueLabels(): string {
    return Array.from(this.selectedValuesMap.values())
      .map(value => this.translate.translate<string>(value))
      .join(', ');
  }

  handleSelectValue(item: { label: string; value: any }): void {
    if (item.value === undefined) {
      this.selectedValuesMap.clear();
      this.selectedValuesMap.set(item.value, item.label);
      this.selectedValuesChanged.emit(
        Array.from(this.selectedValuesMap.keys())
      );
      return;
    }

    if (this.selectedValuesMap.has(item.value)) {
      this.selectedValuesMap.delete(item.value);
      if (this.selectedValuesMap.size === 0) {
        this.selectedValuesMap.set(this.items[0].value, this.items[0].label);
      }
      this.selectedValuesChanged.emit(
        Array.from(this.selectedValuesMap.keys())
      );
      return;
    }

    this.selectedValuesMap.set(item.value, item.label);
    if (this.selectedValuesMap.has(undefined)) {
      this.selectedValuesMap.delete(undefined);
    }
    this.selectedValuesChanged.emit(Array.from(this.selectedValuesMap.keys()));
  }

  handleRemove(): void {
    this.selectedValuesMap.clear();
    this.selectedValuesMap.set(this.items[0].value, this.items[0].label);
    this.selectedValuesChanged.emit(Array.from(this.selectedValuesMap.keys()));
  }
}
